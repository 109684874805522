// NewIndex.js
import React, { useState } from 'react';
import '../css/Login.css';
import logo from '../imgs/logo.gif';
import axios from 'axios';



function NewIndex() {
    const [rut, setRut] = useState('');
    const [local, setLocal] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        
        try {
          const response = await axios.post('https://apigabtecbi.automarco.cl:3003/api/validaCliente', {
            
                param1: rut,
                param2: local
            
          });
          
          if (response.data.clienteNombre === 'Cliente Desconocido') {
            alert('La combinación RUT y Sucursal no existe');
            setRut('');
            setLocal('');
          } else {
            const formattedRut = rut.replace(/-/g, ''); // Reemplaza todos los guiones con una cadena vacía
            const url = `/#/${formattedRut}${local}`;

            // Redirigir a la URL construida
            window.location.href = url;
          }
        } catch (error) {
          console.error('Error:', error);
          alert('Ocurrió un error al consultar el cliente.');
        }
      };

    return (
        <div className="new-index-body">
            <div className="login-container" >
                <div className="login-header">
                    <img src={logo} alt="Logo" className="logo" />
                    <h2>Ingrese Cliente</h2>
                    { /*</div><p>Inicia sesión para continuar</p> */ }
                </div>
                <form className="consult-form" onSubmit={handleSubmit}>
                    <input type="text" placeholder="RUT (Sin puntos y con guión)" className="input-field" value={rut}
            onChange={(e) => setRut(e.target.value)}/>
                    <input type="text" placeholder="Sucursal" className="input-field" value={local}
            onChange={(e) => setLocal(e.target.value)}/>
                    <button type="submit" className="login-button">Continuar</button>
                </form>
            </div> 
        </div>
          );
}

export default NewIndex;