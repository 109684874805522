// App.js
import 'bootstrap/dist/css/bootstrap.min.css';
import Tabs from './tabs/Tabs';
import NewIndex from './pages/NewIndex';  // Importa el nuevo index
//import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { HashRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className="App">
      
        <Routes>
          <Route path="/:cli//*" element={<Tabs />} />
          <Route path="/index" element={<NewIndex />} /> {/* Nueva ruta */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
