import React, {useState, useEffect} from 'react';
import {toast} from 'react-toastify';
import axios from 'axios';
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Pagination from 'react-bootstrap/Pagination';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { useRef } from 'react';
import { useLocation } from 'react-router-dom'



const Tab5 = ({cli}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;
  const [data, setData] = useState([]);
  const [modalAbierta, setModalAbierta] = useState(false);
  const [carroItems, setCarroItems] = useState([]);
  const [cantidadCarro, setCantidadCarro] = useState('');
  const [lineas, setLineas] = useState([]);
  //const [lineaSeleccionada, setLineaSeleccionada] = useState('');
  const [RangoSeleccionado, setRangoSeleccionado] = useState('');
  
  

  const location = useLocation();
 
  const loadData = async () => {
        let buscar = document.getElementById('search').value;

        // let buscar = document.getElementById('types_input').value;
        await axios.get("https://apigabtecbi.automarco.cl:3003/api/historico", {
            params: {
              ven:  cli ,
              bus: buscar, 
            }
        }).then((response) => {
           console.log('Data received from backend:', response.data);
            setData(response.data);
         })
     
        
  };

  const effectRan = useRef(false);

  useEffect(() => {
    const input = document.getElementById('search');
    if (input) {
        input.setAttribute('autocomplete', 'off');
    }
    if (!effectRan.current) {
    loadData();
    }
    return () => {effectRan.current = true};
  },[]);

useEffect(() => {
    const fetchLineas = async () => {
        try {
            const response = await axios.get("https://apigabtecbi.automarco.cl:3003/api/select-lineas");
            setLineas(response.data);
        } catch (error) {
            console.error('Error al obtener las líneas:', error);
        }
    };

    fetchLineas();
}, []);

async function busqueda() {
  await loadData(); // Esperar a que loadData termine
  // Ahora puedes realizar la búsqueda en los datos actualizados
  const buscar = document.getElementById('search').value;
  if (data && data.length > 0) {
    const resultadosFiltrados = data.filter((item) => item.nombre && item.nombre.includes(buscar)); // Verificar si item es definido antes de acceder a la propiedad

    // Realiza las operaciones necesarias con los resultados
    console.log(resultadosFiltrados);

    // Resto de tu lógica
  } else {
    console.log('No hay datos para realizar la búsqueda.');
  }
}

  const formatoNumero = (numero) => {
    return numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  
  const cerrarModal = () => {
    setModalAbierta(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const obtenerProdId = (item) => {
    return item.prod_id;
  };

  const handleSelectChange = (e) => {
    const valorSeleccionado = e.target.value;
    //setRangoSeleccionado(valorSeleccionado);
  
    // Cambia el contenido del h3 basado en la selección
    const tituloElement = document.querySelector('h3');
    switch (valorSeleccionado) {
      case '1':
        tituloElement.textContent = 'Ventas el último mes';
        break;
      case '2':
        tituloElement.textContent = 'Ventas los 3 últimos meses';
        break;
      case '3':
        tituloElement.textContent = 'Ventas los 6 últimos meses';
        break;
      default:
        tituloElement.textContent = 'Ventas';
    }
  
    // Llama a la función de búsqueda
    busqueda();
  };
  
  
  // Calcula el índice de inicio y fin para los elementos de la página actual
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Filtra los elementos a mostrar en la página actual
  const itemsFiltrados = data.filter(
    (item) => !RangoSeleccionado || item.cla_nombre === RangoSeleccionado
    
  );
  const totalFilteredPages = Math.ceil(itemsFiltrados.length / itemsPerPage);
  const itemsFiltrados2 = itemsFiltrados.slice(startIndex, endIndex);

  const maxVisiblePages = 10;
  const currentBlock = Math.ceil(currentPage / maxVisiblePages);
  const startPage = (currentBlock - 1) * maxVisiblePages + 1;
  const endPage = Math.min(startPage + maxVisiblePages - 1, totalFilteredPages);

console.log('currentPage:', currentPage);
console.log('itemsFiltrados.length:', itemsFiltrados.length);
console.log('Total Pages:', Math.ceil(itemsFiltrados.length / itemsPerPage));

 
  

  return (
    <><h3 style={{ marginTop: "10px"}}>Ventas el último mes</h3><div style={{ marginTop: "30px", marginLeft: "10px", marginRight: "0px" }}>
      <div className='form-inline'>
        <div id="field_wrapper" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <select id="search"
            className="form-select"
            style={{ marginLeft: '10px', width: '200px', fontSize: "12px" }}
            value={RangoSeleccionado} // Usar el estado RangoSeleccionado como valor
            onChange={handleSelectChange}
          >
            <option value="">Seleccione un rango</option>
            <option key="1" value="1">Ventas el último mes</option>
            <option key="2" value="2">Ventas los 3 últimos meses</option>
            <option key="3" value="3">Ventas los 6 últimos meses</option>
          </select>

          {/*<select className="form-select" style={{ marginLeft: '10px', width: '200px' }} value={lineaSeleccionada}
onChange={(e) => setLineaSeleccionada(e.target.value)}>
                
        <option value="">Selecciona una línea</option>
        {lineas.map((linea) => (
            <option key={linea.cla_nombre} value={linea.cla_nombre}>
                {linea.cla_nombre}
            </option>
        ))}
                   
        </select>*/}
        </div>
      </div>
      <br></br>
      <div className="my-class-form-control-group">
        <Table size="sm">
          <thead>
            <tr>
              <th>Código</th>
              <th>Cod. Fab</th>
              <th>Linea</th>
              <th>Marca</th>
              <th>Pr Lista</th>
              <th>Pr Compra</th>
              <th>Unid</th>
              {/*<!--th>Dscto</th>
    <th>Neto</th-->*/}
              <th>Fecha</th>


            </tr>
          </thead>
          <tbody>
            {itemsFiltrados2.length === 0 ? (
              <tr>
                <td colSpan="8" style={{ textAlign: "center" }}>
                  No hay resultados
                </td>
              </tr>
            ) : (
              itemsFiltrados2.map((item, index) => {
                //const [data2, setData2] = useState([]);
                return (


                  <tr style={{ textAlign: "left" }} key={`${item.prod_id}-${item.fecha_doc}`}>
                    <td style={{ borderBottomWidth: "0px", width: "12%", whiteSpace: 'nowrap' }}>{item.prod_id}</td>
                    <td style={{ borderBottomWidth: "0px", width: "12%", whiteSpace: 'nowrap' }}>{item.id_prov}</td>
                    <td style={{ borderBottomWidth: "0px", width: "12%", whiteSpace: 'nowrap' }}> {item.cla_nombre}</td>
                    <td style={{ borderBottomWidth: "0px", width: "12%", whiteSpace: 'nowrap' }}> {item.marca_nombre}</td>
                    <td style={{ borderBottomWidth: "0px", width: "12%", whiteSpace: 'nowrap' }}> ${formatoNumero(item.prod_precio)}</td>
                    <td style={{ borderBottomWidth: "0px", width: "12%", whiteSpace: 'nowrap' }}> ${formatoNumero(parseInt(item.precio))}</td>
                    <td style={{ borderBottomWidth: "0px", width: "12%", whiteSpace: 'nowrap' }}> {item.unidades}</td>
                    {/*<td style={{borderBottomWidth: "0px",width: "12%", whiteSpace: 'nowrap'}}> {item.descto}%</td>
                    <td style={{borderBottomWidth: "0px",width: "12%", whiteSpace: 'nowrap'}}> ${formatoNumero(parseInt(item.neto))}</td>*/}
                    <td style={{ borderBottomWidth: "0px", width: "12%", whiteSpace: 'nowrap' }}> {item.fecha_doc}</td>

                  </tr>
                );
              })
            )}

          </tbody>
        </Table>
        
      </div>
      <Pagination className="pagination">
        <Pagination.Prev
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)} />
        {Array.from({ length: (endPage - startPage + 1) }, (_, index) => (
          <Pagination.Item
            key={startPage + index}
            active={startPage + index === currentPage}
            onClick={() => handlePageChange(startPage + index)}
            className="pagination2"
          >
            {startPage + index}
          </Pagination.Item>
        ))}
        <Pagination.Next
          disabled={currentPage === totalFilteredPages}
          onClick={() => handlePageChange(currentPage + 1)} />
      </Pagination>
    </div></>
  )
}

export default Tab5;