import React from 'react';


const Tab = ({ label, isActive, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`nav-item`}
      style={{
        cursor: 'pointer',
        marginRight: '0px', // Añade espacio entre las pestañas
      }}
    >
      <div
        className={`nav-link ${isActive ? 'active' : ''}`}
        style={{
          padding: '10px 15px', // Ajusta el espacio interno
          backgroundColor: isActive ? '#007BFF' : 'white', // Colores de fondo
          color: isActive ? 'white' : 'black', // Color del texto
          border: '1px solid #007BFF', // Bordes
          borderRadius: '0px', // Bordes redondeados
        }}
      >
        {label}
      </div>
    </div>
  );
};
export default Tab;